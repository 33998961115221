import { IShopifyCartProduct, IShopifyCartProductsQueryResponse } from '@lib/shopify/interfaces'

export const decodeBase64 = (str: string): string => Buffer.from(str, 'base64').toString()

export default class CartAPIParser {
  static parseProductQueryResponse(data: IShopifyCartProductsQueryResponse): IShopifyCartProduct[] {
    return data.nodes.map((product) => ({
      id: decodeBase64(product.id),
      vendor: product.vendor,
      metafields: product.metafields.edges.map((metafield) => ({
        id: decodeBase64(metafield.node.id),
        key: metafield.node.key,
        value: metafield.node.value
      })),
      variants: product.variants.edges.map((variant) => ({
        id: decodeBase64(variant.node.id),
        sku: variant.node.sku,
        quantityAvailable: variant.node.quantityAvailable,
        availableForSale: variant.node.availableForSale,
        currentlyNotInStock: variant.node.currentlyNotInStock,
        metafields: variant.node.metafields.edges.map((metafield) => ({
          id: decodeBase64(metafield.node.id),
          key: metafield.node.key,
          value: metafield.node.value
        }))
      }))
    }))
  }
}
