// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs'
import { BrowserTracing } from '@sentry/tracing'

const SENTRY_DSN =
  process.env.SENTRY_DSN ||
  process.env.NEXT_PUBLIC_SENTRY_DSN ||
  'https://c32d893b862449bcb7a4798fb21c09ed@o848580.ingest.sentry.io/5815700'

Sentry.init({
  dsn: process.env.NEXT_PUBLIC_SENTRY_DISABLED ? null : SENTRY_DSN,
  integrations: [
    new BrowserTracing({
      tracingOrigins: ['api.yoself.de']
    })
  ],
  tracesSampleRate: 1.0
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
})
