import React, { useReducer, useState, useEffect } from 'react'
import { useRouter } from 'next/router'
import shopifyClient from '@lib/shopify/api/checkout/CartAPI'
import trackRemoveFromCart from '@modules/common/lib/tracking/trackRemoveFromCartEvent'
import trackAddToCartEvent from '@modules/common/lib/tracking/trackAddToCartEvent'
import { IShopifyCheckout, IShopifyLineItem } from '@lib/shopify/interfaces'
import {
  mapShopifyLineItemToTrackingData,
  mapVariantProductToTrackingData
} from '@modules/common/lib/tracking/mapToTrackingData'
import mapProductToCartItem from '@modules/products/mappers/mapProductToCartItem'
import { LOCAL_STORAGE_KEY } from '@utils/constants'
import {
  IProduct,
  IProductsBySlug,
  IProductVariant
} from '@modules/products/interfaces/productInterfaces'
import CartService from '@lib/cartService'
import { IGlobalContext } from '../interfaces'

import productReducer from './productReducer'
import cartItemReducer, { ADD_CART_ITEMS } from './cartItemReducer'

const cartService = new CartService(shopifyClient)

/* eslint-disable */
export const defaultValues = {
  loading: false,
  coupon: {
    error: '',
    code: '',
    description: '',
    amount: '',
  },
  productsBySlug: {},
  cartItems: [],
  checkout: {
    id: null,
    lineItems: [],
    totalPrice: null,
    webUrl: '',
  },
  shopifyClient,
  showShoppingCart: false,
  setShowShoppingCart: (status: boolean): void => { },
  removeLineItem: (checkoutId: string, lineItem: IShopifyLineItem): void => { },
  updateLineItem: (checkoutId: string, lineItem: IShopifyLineItem, quantity: number): void => { },
  setProductsBySlug: (productsBySlug: IProductsBySlug): void => { },
  addProductToCart: (variant: IProductVariant, product: IProduct): void => { }
}
/* eslint-enable */

export const StoreContext = React.createContext(defaultValues)

export const StoreProvider: React.FC<IGlobalContext> = ({ children, productMap }) => {
  const [checkout, setCheckout] = React.useState(defaultValues.checkout)
  const [loading, setLoading] = React.useState(false)
  const [cartItems, dispatchCartProductAction] = useReducer(
    cartItemReducer,
    defaultValues.cartItems
  )
  const [productsBySlug, dispatchProductAction] = useReducer(productReducer, productMap || {})
  const [showShoppingCart, setShowShoppingCart] = useState(false)
  // const [showShoppingCartNotification, setShowShoppingCartNotification] = useState(false)

  const setCheckoutItem = (_checkout) => {
    localStorage.setItem(LOCAL_STORAGE_KEY, _checkout.id)
    setCheckout(_checkout)
  }

  React.useEffect(() => {
    const initializeCheckout = async () => {
      const existingCheckoutID = localStorage.getItem(LOCAL_STORAGE_KEY) || null

      if (existingCheckoutID && existingCheckoutID !== `null`) {
        try {
          const existingCheckout: IShopifyCheckout = await shopifyClient.checkout.fetch(
            existingCheckoutID
          )

          if (!existingCheckout.completedAt) {
            const productIds = existingCheckout.lineItems.map(
              (lineItem) => lineItem.variant.product.id
            )
            const items = await cartService.getItemsByProductIds(productIds)
            dispatchCartProductAction({
              type: ADD_CART_ITEMS,
              payload: {
                cartItems: items
              }
            })

            setCheckoutItem(existingCheckout)
            return
          }
        } catch (e) {
          console.log('Initializing Cart failed', e.message)
          localStorage.setItem(LOCAL_STORAGE_KEY, null)
        }
      }

      const newCheckout: IShopifyCheckout = await shopifyClient.checkout.create()
      setCheckoutItem(newCheckout)
    }

    initializeCheckout()
  }, [])

  const router = useRouter()

  const addVariantToCart = (variant: IProductVariant, product: IProduct) => {
    const mappedCartProduct = mapProductToCartItem(product)

    setLoading(true)

    dispatchCartProductAction({
      type: ADD_CART_ITEMS,
      payload: {
        cartItems: [mappedCartProduct]
      }
    })

    const checkoutID = checkout.id

    const lineItemsToUpdate = [
      {
        variantId: variant.shopifyVariantId,
        quantity: 1
      }
    ]

    return shopifyClient.checkout.addLineItems(checkoutID, lineItemsToUpdate).then((response) => {
      trackAddToCartEvent(mapVariantProductToTrackingData(variant, product, 1))
      setCheckout(response)
      setLoading(false)
    })
  }

  const updateLineItem = (checkoutID, lineItem, quantity) => {
    const lineItemsToUpdate = [{ id: lineItem.id, quantity: parseInt(quantity, 10) }]

    return shopifyClient.checkout
      .updateLineItems(checkoutID, lineItemsToUpdate)
      .then((response) => {
        if (quantity > lineItem.quantity) {
          trackAddToCartEvent(mapShopifyLineItemToTrackingData(lineItem, cartItems, quantity))
        } else {
          trackRemoveFromCart(lineItem, cartItems, quantity)
        }
        setCheckout(response)
      })
  }

  const removeLineItem = (checkoutID, lineItem: IShopifyLineItem) =>
    shopifyClient.checkout.removeLineItems(checkoutID, [lineItem.id]).then((res) => {
      trackRemoveFromCart(lineItem, cartItems, lineItem.quantity)
      setCheckout(res)
    })

  useEffect(() => {
    if (showShoppingCart === true) {
      router.push('#cart=true')
      return
    }

    const { cart } = router.query

    if (showShoppingCart === false && cart) {
      router.back()
    }
  }, [showShoppingCart])

  useEffect(() => {
    router.beforePopState(() => {
      if (showShoppingCart === true) {
        setShowShoppingCart(false)
      }

      return true
    })
  })

  return (
    <StoreContext.Provider
      value={{
        ...defaultValues,
        productsBySlug,
        setProductsBySlug: (_productsBySlug) =>
          dispatchProductAction({
            type: 'FETCH_PRODUCTS',
            payload: { productsBySlug: _productsBySlug }
          }),
        checkout,
        cartItems,
        showShoppingCart,
        setShowShoppingCart,
        addProductToCart: addVariantToCart,
        updateLineItem,
        removeLineItem,
        loading
      }}>
      {children}
    </StoreContext.Provider>
  )
}

export default StoreContext
