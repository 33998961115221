import { IShopifyProduct, IShopifyProductVariant } from '@lib/shopify/interfaces'
import { IProductVariant, StockStatus } from '@modules/products/interfaces/productInterfaces'

export const selectPrice = (variant: IShopifyProductVariant): string => variant.priceV2.amount

export const selectRegularPrice = (variant: IShopifyProductVariant): string =>
  variant.compareAtPriceV2?.amount || variant.priceV2.amount

export const getStockStatus = (variant: IShopifyProductVariant): StockStatus => {
  if (variant.availableForSale) {
    return StockStatus.instock
  }
  if (variant.quantityAvailable > 0 || variant.availableForSale) {
    return StockStatus.instock
  }

  if (variant.currentlyNotInStock === true) {
    return StockStatus.onbackorder
  }

  return StockStatus.outofstock
}

export const selectDeliveryTime = (
  variant: IShopifyProductVariant,
  product: IShopifyProduct
): string => {
  if (product.deliveryTime) {
    return product.deliveryTime.value
  }
  return variant.deliveryTime?.value || null
}

export const selectAvailabilityDate = (
  variant: IShopifyProductVariant,
  product: IShopifyProduct
): string => {
  if (product.availabilityDate) {
    return product.availabilityDate.value
  }
  return variant.availabilityDate?.value || null
}

export default function mapShopifyVariant(
  productVariant: IShopifyProductVariant,
  product: IShopifyProduct
): IProductVariant {
  return {
    availabilityDate: productVariant.availabilityDate?.value || null,
    deliveryTime: selectDeliveryTime(productVariant, product),
    name: productVariant.title,
    price: selectPrice(productVariant),
    regularPrice: selectRegularPrice(productVariant),
    sku: productVariant.sku,
    stockStatus: getStockStatus(productVariant),
    shopifyVariantId: productVariant.id
  }
}
