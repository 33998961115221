import { IShopifyLineItem } from '@lib/shopify/interfaces'
import selectInfoFromCartProducts from '@context/selectors'
import trackEvent from './trackEvent'
import { ICartItem } from '../../../products/interfaces/productInterfaces'

const trackRemoveFromCart = (
  lineItem: IShopifyLineItem,
  cartItems: ICartItem[],
  quantity = 1
): void => {
  const { brand } = selectInfoFromCartProducts(cartItems, lineItem)
  trackEvent({
    event: 'remove_from_cart',
    ecommerce: {
      items: [
        {
          item_brand: brand,
          item_id: lineItem.variant.sku,
          item_name: lineItem.title,
          price: lineItem.variant.priceV2.amount,
          quantity
        }
      ]
    }
  })

  trackEvent({
    event: 'ua_remove_from_cart',
    ecommerce: {
      currencyCode: 'EUR',
      remove: {
        products: [
          {
            brand,
            category: '',
            coupon: '',
            id: lineItem.variant.sku,
            name: lineItem.variant.title,
            price: lineItem.variant.priceV2.amount,
            quantity,
            variant: lineItem.variant.sku
          }
        ]
      }
    }
  })
}

export default trackRemoveFromCart
