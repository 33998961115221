import { ShopifyBrowserClient } from '@lib/shopify/api/checkout/CartAPI'
import Parser from '@lib/shopify/api/checkout/CartAPIParser'
import makeProductQuery from '@lib/shopify/api/checkout/makeProductQuery'
import mapShopifyPartialProductToCartItem from '@lib/shopify/mapper/mapShopifyPartialProductToCartItem'
import { ICartItem } from '@modules/products/interfaces/productInterfaces'

export default class CartService {
  client: ShopifyBrowserClient

  constructor(shopifyApiClient: ShopifyBrowserClient) {
    this.client = shopifyApiClient
  }

  async getItemsByProductIds(productIds: string[]): Promise<ICartItem[]> {
    const productQuery = makeProductQuery(this.client)
    const { data = [] } = await this.client.graphQLClient.send(productQuery, {
      ids: productIds
    })
    const parsedCartProducts = Parser.parseProductQueryResponse(data)
    const mappedCartProducts: ICartItem[] = parsedCartProducts.map(
      mapShopifyPartialProductToCartItem
    )

    return mappedCartProducts
  }
}
