import { IProduct, IProductsBySlug } from '../modules/products/interfaces/productInterfaces'

export const FETCH_PRODUCTS = 'FETCH_PRODUCTS'

type IProductState = {
  [sku: string]: IProduct
}

type IProductAction = {
  type: string
  payload: {
    productsBySlug: IProductsBySlug
  }
}
const initialState: IProductState = {}
const productReducer = (
  state: IProductState = initialState,
  action: IProductAction
): IProductState => {
  switch (action.type) {
    case FETCH_PRODUCTS:
      return {
        ...state,
        ...action.payload.productsBySlug
      }
    default: {
      return state
    }
  }
}

export default productReducer
