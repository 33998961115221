import { ICartItem } from '@modules/products/interfaces/productInterfaces'
import { AVAILABILITY_DATE_KEY, DELIVERY_TIME_KEY } from '../constants'
import { IShopifyCartProduct, IShopifyProductVariant } from '../interfaces'
import { getStockStatus } from './mapVariant'

const getMetafieldForEntity = (entity, metafieldKey): string | null =>
  entity.metafields.reduce((result, metafield) => {
    if (metafield.key === metafieldKey) {
      return metafield.value
    }
    return result
  }, null)

export const getDeliveryTime = (
  variant: Partial<IShopifyProductVariant>,
  product: IShopifyCartProduct
): string =>
  getMetafieldForEntity(product, DELIVERY_TIME_KEY) ||
  getMetafieldForEntity(variant, DELIVERY_TIME_KEY)

export const getAvailabilityDate = (
  variant: Partial<IShopifyProductVariant>,
  product: IShopifyCartProduct
): string =>
  getMetafieldForEntity(product, AVAILABILITY_DATE_KEY) ||
  getMetafieldForEntity(variant, AVAILABILITY_DATE_KEY)

export default function mapShopifyPartialProductToCartItem(
  product: IShopifyCartProduct
): ICartItem {
  return {
    shopifyProductId: product.id,
    brand: product.vendor,
    variants: product.variants.map((variant) => ({
      shopifyVariantId: variant.id,
      stockStatus: getStockStatus(variant as unknown as IShopifyProductVariant),
      availabilityDate: getAvailabilityDate(variant, product),
      deliveryTime: getDeliveryTime(variant, product)
    }))
  }
}
