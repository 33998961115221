import { ICartItem, IProduct } from '../interfaces/productInterfaces'

export default function mapProductToCartItem(product: IProduct): ICartItem {
  return {
    shopifyProductId: product.shopifyProductId,
    brand: product.brand.name,
    variants: product.variants.map((productVariant) => ({
      shopifyVariantId: productVariant.shopifyVariantId,
      stockStatus: productVariant.stockStatus,
      availabilityDate: productVariant.availabilityDate,
      deliveryTime: productVariant.deliveryTime
    }))
  }
}
