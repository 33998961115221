import {
  IDatoCMSAdditionalInformation,
  IDatoCMSBenefit,
  IDatoCMSBrand,
  IDatoCMSImage,
  IDatoCMSLongDescription,
  IDatoCMSSeoTags,
  IDatoCMSVideo
} from '../../common/interfaces/datoCMSInterfaces'

export interface IProduct {
  additionalInformation: Array<IDatoCMSAdditionalInformation>
  availabilityDate: string
  benefits: Array<IDatoCMSBenefit>
  brand: IDatoCMSBrand
  deliveryTime: string
  images: Array<IDatoCMSImage>
  longDescription: IDatoCMSLongDescription
  name: string
  price: string
  regularPrice: string
  relatedProductSlugs: Array<string>
  seoTags: IDatoCMSSeoTags
  shortDescription: string
  slug: string
  sku: string
  stockStatus: StockStatus
  variantsBySKU: IProductVariantsBySKU
  video: IDatoCMSVideo
  variants: IProductVariant[]
  shopifyProductId: string // needed to retrieve items for cart
  shopifyProductHandle: string
}

export interface IProductVariant {
  availabilityDate: string
  deliveryTime: string
  name: string
  price: string
  regularPrice: string
  stockStatus: StockStatus
  sku: string
  shopifyVariantId: string
}

export interface IProductVariantsBySKU {
  [sku: string]: IProductVariant
}

export interface IProductsBySlug {
  [slug: string]: IProduct
}

export interface ICartItem {
  shopifyProductId: string
  brand: string
  variants: {
    shopifyVariantId: string
    stockStatus: StockStatus
    availabilityDate?: string
    deliveryTime?: string
  }[]
}

export enum StockStatus {
  onbackorder = 'onbackorder',
  outofstock = 'outofstock',
  instock = 'instock'
}
