import { ICartItem, StockStatus } from '@modules/products/interfaces/productInterfaces'
import { IShopifyLineItem } from '@lib/shopify/interfaces'
import { decodeBase64 } from '@lib/shopify/api/checkout/CartAPIParser'

const selectInfoFromCartItems = (
  cartItems: ICartItem[],
  lineItem: IShopifyLineItem
): {
  brand: string
  availabilityDate?: string
  deliveryTime?: string
  stockStatus: StockStatus
} => {
  const product = cartItems.find(
    (cartItem) => cartItem.shopifyProductId === decodeBase64(lineItem.variant.product.id)
  )
  const variant = product.variants.find(
    (cartItemVariant) => cartItemVariant.shopifyVariantId === decodeBase64(lineItem.variant.id)
  )
  return {
    brand: product.brand,
    availabilityDate: variant.availabilityDate,
    deliveryTime: variant.deliveryTime,
    stockStatus: variant.stockStatus
  }
}

export default selectInfoFromCartItems
