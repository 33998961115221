declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    dataLayer: Record<string, any>[]
  }
}

export enum PaymentMethods {
  CreditCard = 'CreditCard',
  Klarna = 'Klarna',
  PayPal = 'PayPal',
  SEPA = 'SEPA'
}

export const paymentTypesMap = {
  creditCard: PaymentMethods.CreditCard,
  klarna: PaymentMethods.Klarna,
  payPal: PaymentMethods.PayPal
}

export const trackCookieConsentGiven = (): void => {
  if (window && !process.env.NEXT_PUBLIC_DISABLE_TRACKING) {
    window.dataLayer.push({
      event: 'consent_given'
    })
  }
}

export const trackLoaded = (): void => {
  if (window && !process.env.NEXT_PUBLIC_DISABLE_TRACKING) {
    window.dataLayer.push({ event: 'gtm.load' })
  }
}

export const addPageviewAndOptimizeEvent = (): void => {
  if (window && !process.env.NEXT_PUBLIC_DISABLE_TRACKING) {
    window.dataLayer.push({ event: 'optimize.event' })
    // fix delayed title update https://github.com/nfl/react-helmet/issues/189
    setTimeout(() => window.dataLayer.push({ event: 'pageview' }), 100)
  }
}

export const setOriginalPageLocation = (): void => {
  // avoid rogue referral issue in Universal Analytics
  // https://www.simoahava.com/gtm-tips/fix-rogue-referral-problem-single-page-sites/
  if (window && !process.env.NEXT_PUBLIC_DISABLE_TRACKING) {
    window.dataLayer.push({
      originalPageLocation:
        document.location.origin + document.location.pathname + document.location.search
    })
  }
}
