import trackEvent from './trackEvent'

export interface ICartTrackingData {
  brand: string
  itemId: string
  itemName: string
  price: string
  quantity: number
}

const trackAddToCartEvent = (data: ICartTrackingData): void => {
  trackEvent({
    event: 'add_to_cart',
    ecommerce: {
      items: [
        {
          item_brand: data.brand,
          item_id: data.itemId,
          item_name: data.itemName,
          price: data.price,
          quantity: data.quantity
        }
      ]
    }
  })

  trackEvent({
    event: 'ua_add_to_cart',
    ecommerce: {
      currencyCode: 'EUR',
      add: {
        products: [
          {
            brand: data.brand,
            category: '',
            coupon: '',
            id: data.itemId,
            name: data.itemName,
            price: data.price,
            quantity: data.quantity,
            variant: data.itemId
          }
        ]
      }
    }
  })
}

export default trackAddToCartEvent
